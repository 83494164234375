<template>
  <section class="text-left page-section">
    <h2>
      Estabelecimentos
    </h2>
    <b-container>
      <b-row>
        <b-col md="6" sm="12" class="mb-3">
          <input
            type="text"
            v-model="nome"
            class="form-control"
            placeholder="Nome"
            @change="changedFilter()"
            @keydown.enter="changedFilter()"
          />
        </b-col>
        <b-col md="6" sm="12" class="mb-3"
          ><v-select
            id="input-2"
            v-model="tipoEstabelecimento"
            :options="tiposEstabelecimento"
            placeholder="Categoria do Estabelecimento"
            label="nome"
          ></v-select
        ></b-col>
        <b-col md="5" sm="12" class="mb-3">
          <v-select
            id="input-2"
            v-model="estado"
            :options="estados"
            placeholder="Estado"
            label="name"
          ></v-select>
        </b-col>
        <b-col md="5" sm="12" class="mb-3">
          <v-select
            id="input-2"
            v-model="cidade"
            :options="cidades"
            placeholder="Cidade"
            label="nome"
            :disabled="!cidades.length > 0"
          >
          </v-select>
        </b-col>
        <b-col md="2" sm="12" class="mb-3">
          <button class="btn btn-secondary btn-block" @click="reload()">
            Limpar
          </button>
        </b-col>
      </b-row>
    </b-container>

    <div class="container">
      <div
        v-infinite-scroll="loadMore"
        infinite-scroll-disabled="loading"
        infinite-scroll-distance="100"
      >
        <p class="mt-4 text-right">
          <b>Total:</b> {{ totalResultado }} Estabelecimento{{
            totalResultado > 1 ? "s" : ""
          }}
        </p>
        <fnl-list-item v-for="(item, index) in estabelecimentos" :key="index">
          <template v-slot:imagem>
            <img
              src="@/assets/img/store-icon.svg"
              alt="Ícone de lojinha"
              style="border: 1px solid rgba(0,0,0,0.15);"
            />
          </template>
          <template v-slot:info_primary>
            <p class="my__list__item__main__info__primary__title">
              {{ item.nome }}
            </p>
            <p class="my__list__item__main__info__primary__subtitle">
              {{ item.cidade }} - {{ item.estado.uf }}
            </p>
          </template>
          <template v-slot:more_actions>
            <b-button variant="outline-secondary" @click="editar(item.id)"
              >Editar</b-button
            >
            <b-button
              variant="outline-secondary"
              class="ml-2"
              @click="showModal(item)"
            >
              Excluir
            </b-button>
          </template>
          <template v-slot:overplus>
            <h5>Estabelecimento</h5>
            <hr />
            <p>{{ item.cnpj }}</p>
            <h6>Tategoria do estabelecimento</h6>
            <div
              v-for="tipo in item.tiposEstabelecimento"
              :key="tipo.nome"
              pill
              class="mr-2 tag-item"
              variant="secondary"
            >
              {{ tipo.nome }}
            </div>
            <h6 class="mt-4">Funcionamento</h6>
            <b-badge
              v-for="dia in item.diasFuncionamento"
              :key="dia"
              pill
              class="mr-2"
              variant="secondary"
              >{{ dia }}</b-badge
            >
            <p class="mt-2">{{ item.horarioFuncionamento }}</p>

            <p class="tag-item" v-if="item.isDelivery">
              Delivery
              <b-badge
                v-if="item.valordelivery"
                pill
                class="mr-2"
                variant="secondary"
                >R$ {{ item.valordelivery }}</b-badge
              >
            </p>
            <p class="tag-item" v-if="item.isPermiteRetirada">Retirada</p>

            <p v-if="item.tempoEspera">
              <b>tempo de espera</b>: {{ item.tempoEspera }} Minutos
            </p>

            <p v-if="item.descricao">{{ item.descricao }}</p>

            <p v-if="item.aceitaRecomendacoes"><b>Aceita recomendações</b></p>

            <h5 class="mt-5">Endereço</h5>
            <hr />
            <p>
              {{
                `${item.enderecoObj.rua},${item.enderecoObj.numero}, ${item.enderecoObj.referencia}, ${item.enderecoObj.bairro}, ${item.enderecoObj.cep}`
              }}
            </p>

            <h5 class="mt-5">Contato</h5>
            <hr />
            <p v-for="(tel, index) in item.telefones" :key="`index${index}`">
              <unicon
                :name="tel.whatsapp ? 'whatsapp' : 'phone'"
                :fill="tel.whatsapp ? 'green' : 'gray'"
              />
              {{ tel.numero ? tel.numero : "( Vazio )" }}
            </p>
            <p v-show="item.instagram">
              <unicon name="instagram" fill="gray" /> {{ item.instagram }}
            </p>
            <p v-show="item.facebook">
              <unicon name="facebook" fill="gray" /> {{ item.facebook }}
            </p>
            <p v-show="item.site">
              <unicon name="globe" fill="gray" /> {{ item.site }}
            </p>
          </template>
        </fnl-list-item>
      </div>
    </div>
    <div
      v-if="loading"
      class="d-flex justify-content-center align-items-center mt-4"
    >
      <b-spinner variant="success" label="Spinning"></b-spinner>
    </div>
    <div>
      <b-modal
        id="mdelete"
        ref="mdelete"
        hide-footer
        title="Deletar Estabelecimento"
      >
        <p class="my-4">
          Deseja Deletar o Estabelecimento {{ objToDelete.nome }}?
        </p>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="hideModal()">
            Cancelar
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="deleteEstabelecimento()"
          >
            Deletar
          </button>
        </div>
      </b-modal>
    </div>
  </section>
</template>
<script>
import Service from "../services/ApiServices";
import FnlListItem from "@/components/FnlListItem";

export default {
  components: {
    FnlListItem
  },
  data() {
    return {
      tiposEstabelecimento: [],
      estabelecimentos: [],
      totalResultado: 0,
      page: 0,
      last: false,
      password: "",
      admin: false,
      nome: "",
      cidade: "",
      tipoEstabelecimento: "",
      replaceContent: false,
      loading: true,
      objToDelete: {},
      estados: [],
      estado: "",
      cidades: [],
      loadingScroll: null
    };
  },

  beforeCreate() {},

  created() {
    if (localStorage.adminnastro && localStorage.adminnastro == "true")
      this.admin = localStorage.adminnastro;

    this.estado = {
      name: localStorage.devFilterEstado ? localStorage.devFilterEstado : ""
    };
    this.cidade = {
      nome: localStorage.devFilterCidade ? localStorage.devFilterCidade : ""
    };
    this.tipoEstabelecimento = {
      nome: localStorage.devFilterTipoEstabelecimento
        ? localStorage.devFilterTipoEstabelecimento
        : ""
    };
    this.nome = localStorage.devFilterNome ? localStorage.devFilterNome : "";

    this.getEstabelecimentos();
    this.getEstados();
    this.getTiposEstabelecimentos();
  },

  watch: {
    admin() {},
    tipoEstabelecimento() {
      if (this.tipoEstabelecimento == null) this.tipoEstabelecimento = "";
      this.changedFilter();
    },
    estado() {
      this.cidades = [];
      this.cidade = "";
      if (!this.estado) {
        this.estado = "";
      } else if (this.estado !== "") {
        this.getCidades();
      }
      this.changedFilter();
    },
    cidade() {
      if (this.cidade == null) {
        this.cidade = "";
      } else if (this.estado !== "") {
        this.changedFilter();
      }
    }
  },

  methods: {
    loadMore() {
      if (!this.last) {
        this.page = this.page + 1;
        const request = new Service();
        this.loading = true;
        request
          .findByParams(
            {
              nome: this.nome,
              estado: this.estado.name ? this.estado.name : "",
              cidade: this.cidade.nome ? this.cidade.nome : "",
              tipoEstabelecimento: this.tipoEstabelecimento.nome
                ? this.tipoEstabelecimento.nome
                : "",
              page: this.page,
              sort: "cidadeObj.nome"
            },
            "empresas/findByParams"
          )
          .then(res => {
            this.last = res.last;
            this.estabelecimentos = this.estabelecimentos.concat(
              ...res.content
            );
            this.loading = false;
            this.totalResultado = res.totalElements;
          });
      }
    },
    getEstabelecimentos() {
      this.loading = true;
      this.page = 0;
      const request = new Service();

      localStorage.setItem(
        "devFilterEstado",
        this.estado.name ? this.estado.name : ""
      );
      localStorage.setItem(
        "devFilterCidade",
        this.cidade.nome ? this.cidade.nome : ""
      );
      localStorage.setItem(
        "devFilterTipoEstabelecimento",
        this.tipoEstabelecimento.nome ? this.tipoEstabelecimento.nome : ""
      );
      localStorage.setItem("devFilterNome", this.nome ? this.nome : "");

      request
        .findByParams(
          {
            nome: this.nome,
            estado: this.estado.name ? this.estado.name : "",
            cidade: this.cidade.nome ? this.cidade.nome : "",
            tipoEstabelecimento: this.tipoEstabelecimento.nome
              ? this.tipoEstabelecimento.nome
              : "",
            page: this.page,
            sort: "cidadeObj.nome"
          },
          "empresas/findByParams"
        )
        .then(res => {
          this.last = res.last;
          this.estabelecimentos = res.content;
          this.loading = false;
          this.totalResultado = res.totalElements;
        });
    },
    changedFilter() {
      this.estabelecimentos = [];
      this.replaceContent = true;
      this.page = 0;
      this.last = false;
      this.getEstabelecimentos();
    },
    reload() {
      this.estabelecimentos = [];
      this.totalElements = 0;
      this.page = 0;
      this.last = false;
      this.nome = "";
      this.tipoEstabelecimento = "";
      this.cidade = "";
      this.estado = "";
      this.getEstabelecimentos();
    },

    showModal(obj) {
      this.objToDelete = obj;
      this.$refs["mdelete"].show();
    },

    hideModal() {
      this.objToDelete = {};
      this.$refs["mdelete"].hide();
    },

    deleteEstabelecimento() {
      if (this.objToDelete.id) {
        const request = new Service();
        request.delete(this.objToDelete.id, "empresas/delete").then(() => {
          this.hideModal();
          this.changedFilter();
          this.getEstabelecimentos();
        });
      }
    },

    getTipos(tipos = []) {
      let resposta = "";
      for (const t of tipos) {
        resposta = resposta + " " + t.nome + " | ";
      }
      return resposta;
    },

    validaLogin(senha = "") {
      if (senha === "laradminifce2020") {
        this.admin = true;
        localStorage.setItem("adminnastro", this.admin);
      } else this.admin = false;
    },

    editar(id) {
      this.$router.push({
        name: "Edit",
        params: {
          id: id
        }
      });
    },

    async getTiposEstabelecimentos() {
      const request = new Service();
      const resp = await request.getAll(
        { sort: "nome", size: 50 },
        "tiposEstabelecimento"
      );
      if (resp) {
        this.tiposEstabelecimento = resp.content;
      }
    },

    async getEstados() {
      const request = new Service();
      const resp = await request.getAll({ sort: "nome", size: 27 }, "estados");
      if (resp) {
        this.estados = resp.content;
      }
    },

    async getCidades() {
      const request = new Service();
      let resp = undefined;
      if (this.estado && this.estado != "")
        resp = await request.getAll(
          { sort: "nome", nomeEstado: this.estado.name },
          "cidades/findByEstado"
        );
      if (resp) {
        this.cidades = resp.content;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.page-section {
  padding: 3rem 0;
}
p a {
  font-size: 0.9rem;
}
</style>
